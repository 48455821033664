<div class="inline-box">
  <svg-icon
    src="assets/images/icons/menu-ninedot.svg"
    alt="Cloud Service Ninedot"
    [svgStyle]="{
      'fill': themeList?.text_login_color,
      'width.px': 24,
      'height.px': 24,
      'cursor': 'pointer'
    }"
    (click)="toggleNineDot()"
  >
  </svg-icon>
  <div class="position-relative">
    <div id="ninedot-component"></div>
  </div>
</div>
